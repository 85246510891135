(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSitesAssociatesController', UserSitesAssociatesController);

    UserSitesAssociatesController.$inject = [
        'approversResponse',
        'lodash',
        'userManager',
        'quoteThresholdsCollectionResponse',
        'thresholdService',
        'siteAssociatesService',
        'siteResponse',
        'rootResourceResponse'
    ];

    function UserSitesAssociatesController(
        approversResponse,
        lodash,
        userManager,
        quoteThresholdsCollectionResponse,
        thresholdService,
        siteAssociatesService,
        siteResponse,
        rootResourceResponse
    ) {
        var vm = this;
        vm.approvers = approversResponse;
        vm.associatesCollection = siteAssociatesService.getSiteAssociates(siteResponse, rootResourceResponse, {limit: 999});
        vm.canEditAssociates = userManager.hasPermission('SITE_PERMISSION_EDIT_ASSOCIATES');
        vm.canEditApprovers = userManager.hasPermission('SITE_PERMISSION_EDIT_APPROVERS');

        vm.quoteThresholds = [];

        if (lodash.has(quoteThresholdsCollectionResponse, 'quoteThresholds') ) {
            vm.quoteThresholds = quoteThresholdsCollectionResponse.quoteThresholds;
            thresholdService.initThresholds(vm.quoteThresholds);
            vm.getQuoteApproverThreshold = thresholdService.getQuoteApproverThreshold;
        }
    }
})();
