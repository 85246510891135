(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sitegroups')
        .controller('SiteGroupsAddEditController', SiteGroupsAddEditController);

    SiteGroupsAddEditController.$inject = ['$state', 'apiClient', 'siteGroupResponse', 'messenger', 'siteGroupCollectionResponse', 'requestDataFactory', 'confirmationModal', 'coreClientsCollectionResponse', 'lodash'];

    function SiteGroupsAddEditController ($state, apiClient, siteGroupResponse, messenger, siteGroupCollectionResponse, requestDataFactory, confirmationModal, coreClientsCollectionResponse, lodash) {
        var vm = this;
        vm.sitegroup = siteGroupResponse;
        vm.siteGroupCollectionResponse = siteGroupCollectionResponse;
        vm.data = requestDataFactory.createRequest(getDefaults());

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        vm.hasCoreClient = (coreClientsCollectionResponse.count > 0) ? true : false;

        vm.selectedCoreClientModel = {
            response: coreClientsCollectionResponse,
            link: coreClientsCollectionResponse ? coreClientsCollectionResponse.getLink('self') : null,
            responseKeyPath: 'coreclients',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            linkParameters: { 'active': 'yes', 'datagroup': 'search' },
            onSelect: changeCoreClient,
            onRemove: changeCoreClient,
            populateDisabled: true
        };

        if(lodash.has(siteGroupResponse, '_links.coreclient')) {
            vm.selectedCoreClientModel.selected = siteGroupResponse._links.coreclient;
            vm.data._links['coreclient'] = siteGroupResponse._links.coreclient;
        }

        function changeCoreClient() {
            if (vm.selectedCoreClientModel.selected) {
                vm.data._links.coreclient = {href: vm.selectedCoreClientModel.selected.href};
            } else {
                vm.data._links.coreclient = null;
            }
        }

        function getDefaults() {
            return {
                id: vm.sitegroup.id || null,
                name: vm.sitegroup.name || null,
                active: vm.sitegroup.active === true || vm.sitegroup.active === false ? vm.sitegroup.active : null
            };
        }

        function updateAction() {
            apiClient.update(vm.sitegroup.getLink('self'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.sitegroups.list.details' }).then(function () {
                        messenger.success('SITE_GROUP_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            apiClient.create(vm.siteGroupCollectionResponse.getLink('create'), vm.data).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: 'dashboard.admin.sitegroups.list' }).then(function () {
                        messenger.success('SITE_GROUP_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(function () {
                return apiClient.delete(vm.sitegroup.getLink('delete')).then(function (response) {
                    if (response) {
                        $state.go('dashboard.admin.sitegroups.list', {}, { reload: 'dashboard.admin.sitegroups.list' }).then(function () {
                            messenger.success('SITE_GROUP_DELETED');
                        });
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                });
            });
        }

    };
})();
