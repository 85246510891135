app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {


        SITE_PERMISSION_EDIT_KPIS:	'Edit Kpis',
        SITE_PERMISSION_VIEW_FILES:	'View Files',
        SITE_PERMISSION_EDIT_FILES:	'Edit Files',
        SITE_PERMISSION_DOWNLOAD_FILES:	'Download Files',
        SITE_PERMISSION_VIEW_NOTES:	'View Notes',
        SITE_PERMISSION_EDIT_NOTES:	'Edit Notes',

        SITE_PERMISSION: 'Site Permissions',
        SITE_PERMISSION_CAN_BE_APPROVER: 'Can Be Approver',
        SITE_PERMISSION_CAN_BE_ASSOCIATE: 'Can Be Associate',
        SITE_PERMISSION_CAN_CANCEL_PATROLS: 'Can Cancel Patrols',
        SITE_PERMISSION_CREATE_JOBS: 'Create Jobs',
        SITE_PERMISSION_CREATE_ACTION: 'Create Actions',
        SITE_PERMISSION_CREATE_ASSETS: 'Create Assets',
        SITE_PERMISSION_CREATE_AUDIT_TEMPLATES: 'Create Audit Templates',
        SITE_PERMISSION_CREATE_AUDITS: 'Create Audits',
        SITE_PERMISSION_CREATE_KPIS: 'Create Kpis',
        SITE_PERMISSION_CREATE_QUOTES: 'Create Quotes',
        SITE_PERMISSION_CREATE_TASKS: 'Create Tasks',
        SITE_PERMISSION_CREATE_ACTIONS: 'Create Actions',
        SITE_PERMISSION_EDIT: 'Edit Sites',
        SITE_PERMISSION_EDIT_ACTIONS: 'Edit Actions',
        SITE_PERMISSION_EDIT_APPROVERS: 'Edit Approvers',
        SITE_PERMISSION_EDIT_ASSETS: 'Edit Assets',
        SITE_PERMISSION_EDIT_ASSOCIATES: 'Edit Associates',
        SITE_PERMISSION_EDIT_CONTACTS: 'Edit Contacts',
        SITE_PERMISSION_EDIT_CONTRACTS: 'Edit Contracts',
        SITE_PERMISSION_EDIT_JOBS: 'Edit Jobs',
        SITE_PERMISSION_EDIT_LOCATIONS: 'Edit Locations',
        SITE_PERMISSION_EDIT_METER_READINGS: 'Edit Meter Readings',
        SITE_PERMISSION_EDIT_METERINFO: 'Edit Meter Info',
        SITE_PERMISSION_EDIT_METERS: 'Edit Meters',
        SITE_PERMISSION_EDIT_PARTNERSHIPS: 'Edit Partnerships',
        SITE_PERMISSION_EDIT_PATROL_INSTANCES: 'Edit Patrol Instances',
        SITE_PERMISSION_ADD_PATROL_INSTANCES: 'Add Patrol Instances',
        SITE_PERMISSION_EDIT_PRIORITIES: 'Edit Priorities',
        SITE_PERMISSION_EDIT_QUOTES: 'Edit Quotes',
        SITE_PERMISSION_QUOTE_VALUE_APPROVAL: 'Quote Value Approver',
        SITE_PERMISSION_EDIT_TASKS: 'Edit Tasks',
        SITE_PERMISSION_EDIT_TENANTS: 'Edit Tenants',
        SITE_PERMISSION_HELPDESK: 'Helpdesk',
        SITE_PERMISSION_CAN_APPROVE_ALL_QUOTES: 'Can Approve All Quotes',
        SITE_PERMISSION_CAN_APPROVE_QUOTE_EXTENSION_REQUESTS: 'Can Approve All Quote Extension Requests',
        SITE_PERMISSION_CAN_APPROVE_ALL_JOBS:'Can Approve All Jobs',
        SITE_PERMISSION_CAN_APPROVE_JOB_EXTENSION_REQUESTS: 'Can Approve All Job Extension Requests',
        SITE_PERMISSION_SUBMIT_P2P_JOB_PO: 'Submit Job P2P PO',
        SITE_PERMISSION_MANAGE_PATROLS: 'Manage Patrols',
        SITE_PERMISSION_TENANT: 'Tenant',
        SITE_PERMISSION_TENANT_PLUS: 'Tenant Plus',
        SITE_PERMISSION_VIEW: 'View Sites',
        SITE_PERMISSION_VIEW_AUDITS: 'View Audits',
        SITE_PERMISSION_EDIT_AUDIT_REFERENCE: 'Edit Audit Reference',
        SITE_PERMISSION_VIEW_KPIS: 'View Kpis',
        SITE_PERMISSION_VIEW_PATROLS: 'View Patrols',
        SITE_PERMISSION_VIEW_ACTIONS: 'View Actions',
        SITE_PERMISSION_VIEW_APPROVERS: 'View Approvers',
        SITE_PERMISSION_VIEW_ASSETS: 'View Assets',
        SITE_PERMISSION_VIEW_ASSOCIATES: 'View Associates',
        SITE_PERMISSION_VIEW_CONTACTS: 'View Contacts',
        SITE_PERMISSION_VIEW_CONTRACTS: 'View Contracts',
        SITE_PERMISSION_VIEW_JOBS: 'View Jobs',
        SITE_PERMISSION_VIEW_LOCATIONS: 'View Locations',
        SITE_PERMISSION_VIEW_METERINFO: 'View Meter Info',
        SITE_PERMISSION_VIEW_METERS: 'View Meters',
        SITE_PERMISSION_VIEW_PARTNERSHIPS: 'View Partnerships',
        SITE_PERMISSION_VIEW_PATROL_INSTANCES: 'View Patrol Instances',
        SITE_PERMISSION_VIEW_PRIORITIES: 'View Priorities',
        SITE_PERMISSION_VIEW_QUOTES: 'View Quotes',
        SITE_PERMISSION_VIEW_TASKS: 'View Tasks',
        SITE_PERMISSION_VIEW_TENANTS: 'View Tenants',
        SITE_PERMISSION_VIEW_SURVEYS: 'View Surveys',
        SITE_PERMISSION_EDIT_SURVEYS: 'Edit Surveys',
        SITE_PERMISSION_CREATE_SURVEYS: 'Create Surveys',
        SITE_PERMISSION_VIEW_SURVEY_SCHEDULES: 'View Survey Schedules',
        SITE_PERMISSION_EDIT_SURVEY_SCHEDULES: 'Edit Survey Schedules',
        SITE_PERMISSION_CREATE_SURVEY_SCHEDULES: 'Create Survey Schedules',
        SITE_PERMISSION_FORCE_COMPLETE_AUDITS: 'Force Complete Audits',
        SITE_PERMISSION_ASSET_USER_IMPORTS: 'Import Assets',
        SITE_PERMISSION_TASK_USER_IMPORTS: 'Import Tasks',
        SITE_PERMISSION_ASSET_TASK_USER_IMPORTS: 'Planner Import',
        SITE_PERMISSION_ACTION_USER_IMPORTS: 'Import Actions',
        SITE_PERMISSION_METER_READINGS_USER_IMPORTS: 'Import Meter Readings',

        SITE_PERMISSION_VIEW_FILES_DETAIL:	' ',
        SITE_PERMISSION_EDIT_FILES_DETAIL:	' ',
        SITE_PERMISSION_DOWNLOAD_FILES_DETAIL:	'Can download files',
        SITE_PERMISSION_VIEW_NOTES_DETAIL:	' ',
        SITE_PERMISSION_EDIT_NOTES_DETAIL:	' ',

        SITE_PERMISSION_CAN_BE_APPROVER_DETAIL: 'Can Be Approver',
        SITE_PERMISSION_CAN_BE_ASSOCIATE_DETAIL: 'Can Be Associate',
        SITE_PERMISSION_CAN_CANCEL_PATROLS_DETAIL: 'Can Cancel Patrol Instances',
        SITE_PERMISSION_CREATE_JOBS_DETAIL: 'Create Jobs',
        SITE_PERMISSION_CREATE_ACTION_DETAIL: 'Create Actions',
        SITE_PERMISSION_CREATE_ASSETS_DETAIL: 'Create Assets',
        SITE_PERMISSION_CREATE_AUDIT_TEMPLATES_DETAIL: 'Create Audit Templates',
        SITE_PERMISSION_CREATE_AUDITS_DETAIL: 'Create Audits',
        SITE_PERMISSION_CREATE_KPIS_DETAIL: 'Create Kpis',
        SITE_PERMISSION_CREATE_QUOTES_DETAIL: 'Create Quotes',
        SITE_PERMISSION_CREATE_TASKS_DETAIL: 'Create Tasks',
        SITE_PERMISSION_CREATE_ACTIONS_DETAIL: 'Create Actions',
        SITE_PERMISSION_EDIT_DETAIL: 'Edit Sites',
        SITE_PERMISSION_EDIT_ACTIONS_DETAIL: 'Edit Actions',
        SITE_PERMISSION_EDIT_APPROVERS_DETAIL: 'Edit Approvers',
        SITE_PERMISSION_EDIT_ASSETS_DETAIL: 'Edit Assets',
        SITE_PERMISSION_EDIT_ASSOCIATES_DETAIL: 'Edit Associates',
        SITE_PERMISSION_EDIT_CONTACTS_DETAIL: 'Edit Contacts',
        SITE_PERMISSION_EDIT_CONTRACTS_DETAIL: 'Edit Contracts',
        SITE_PERMISSION_EDIT_JOBS_DETAIL: 'Edit Jobs',
        SITE_PERMISSION_EDIT_LOCATIONS_DETAIL: 'Edit Locations',
        SITE_PERMISSION_EDIT_METER_READINGS_DETAIL: 'Edit Meter Readings',
        SITE_PERMISSION_EDIT_METERINFO_DETAIL: 'Edit Meter Info',
        SITE_PERMISSION_EDIT_METERS_DETAIL: 'Edit Meters',
        SITE_PERMISSION_EDIT_PARTNERSHIPS_DETAIL: 'Edit Partnerships',
        SITE_PERMISSION_EDIT_PATROL_INSTANCES_DETAIL: 'Edit Patrol Instances',
        SITE_PERMISSION_ADD_PATROL_INSTANCES_DETAIL: 'The ability to Add Patrol Instances',
        SITE_PERMISSION_EDIT_PRIORITIES_DETAIL: 'Edit Priorities',
        SITE_PERMISSION_EDIT_QUOTES_DETAIL: 'Edit Quotes',
        SITE_PERMISSION_EDIT_TASKS_DETAIL: 'Edit Tasks',
        SITE_PERMISSION_QUOTE_VALUE_APPROVAL_DETAIL: 'The ability to secondary approve quote option values',
        SITE_PERMISSION_CANCEL_TASK_EVENTS: 'Cancel Tasks',
        SITE_PERMISSION_CANCEL_TASK_EVENTS_DETAIL: 'Cancel Tasks And All The Jobs Of Those Tasks',
        SITE_PERMISSION_EDIT_TENANTS_DETAIL: 'Edit Tenants',
        SITE_PERMISSION_HELPDESK_DETAIL: 'Helpdesk',
        SITE_PERMISSION_CAN_APPROVE_ALL_QUOTES_DETAIL: 'The ability to approve all Quotes based on site access',
        SITE_PERMISSION_CAN_APPROVE_QUOTE_EXTENSION_REQUESTS_DETAIL: 'The ability to approve all Quote Extension Requests based on site access',
        SITE_PERMISSION_CAN_APPROVE_ALL_JOBS_DETAIL: 'The ability to approve all Jobs based on site access',
        SITE_PERMISSION_CAN_APPROVE_JOB_EXTENSION_REQUESTS_DETAIL: 'The ability to approve all Job Extension Requests based on site access',
        SITE_PERMISSION_SUBMIT_P2P_JOB_PO_DETAIL: 'The ability to Submit any Job PO based on Site Access, also requires Site Permission P2P',
        SITE_PERMISSION_MANAGE_PATROLS_DETAIL: 'Manage Patrols',
        SITE_PERMISSION_TENANT_DETAIL: 'Tenant',
        SITE_PERMISSION_TENANT_PLUS_DETAIL: 'Tenant Plus',
        SITE_PERMISSION_VIEW_DETAIL: 'View Sites',
        SITE_PERMISSION_VIEW_AUDITS_DETAIL: 'View Audits',
        SITE_PERMISSION_EDIT_AUDIT_REFERENCE_DETAIL: 'Edit Audit Reference When Audit Is Active',
        SITE_PERMISSION_VIEW_KPIS_DETAIL: 'View Kpis',
        SITE_PERMISSION_VIEW_PATROLS_DETAIL: 'View Patrols',
        SITE_PERMISSION_VIEW_ACTIONS_DETAIL: 'View Actions',
        SITE_PERMISSION_VIEW_APPROVERS_DETAIL: 'View Approvers',
        SITE_PERMISSION_VIEW_ASSETS_DETAIL: 'View Assets',
        SITE_PERMISSION_VIEW_ASSOCIATES_DETAIL: 'View Associates',
        SITE_PERMISSION_VIEW_CONTACTS_DETAIL: 'View Contacts',
        SITE_PERMISSION_VIEW_CONTRACTS_DETAIL: 'View Contracts',
        SITE_PERMISSION_VIEW_JOBS_DETAIL: 'View Jobs',
        SITE_PERMISSION_VIEW_LOCATIONS_DETAIL: 'View Locations',
        SITE_PERMISSION_VIEW_METERINFO_DETAIL: 'View Meter Info',
        SITE_PERMISSION_VIEW_METERS_DETAIL: 'View Meters',
        SITE_PERMISSION_VIEW_PARTNERSHIPS_DETAIL: 'View Partnerships',
        SITE_PERMISSION_VIEW_PATROL_INSTANCES_DETAIL: 'View Patrol Instances',
        SITE_PERMISSION_VIEW_PRIORITIES_DETAIL: 'View Priorities',
        SITE_PERMISSION_VIEW_QUOTES_DETAIL: 'View Quotes',
        SITE_PERMISSION_VIEW_TASKS_DETAIL: 'View Tasks',
        SITE_PERMISSION_VIEW_TENANTS_DETAIL: 'View Tenants',
        SITE_PERMISSION_VIEW_SURVEYS_DETAIL: 'View Surveys',
        SITE_PERMISSION_EDIT_SURVEYS_DETAIL: 'Edit Surveys',
        SITE_PERMISSION_CREATE_SURVEYS_DETAIL: 'Create Surveys',
        SITE_PERMISSION_VIEW_SURVEY_SCHEDULES_DETAIL: 'View Survey Schedules',
        SITE_PERMISSION_EDIT_SURVEY_SCHEDULES_DETAIL: 'Edit Survey Schedules',
        SITE_PERMISSION_CREATE_SURVEY_SCHEDULES_DETAIL: 'Create Survey Schedules',

        SITE_PERMISSION_EMAIL_TENANTS: 'Tenant Emailer',
        SITE_PERMISSION_EMAIL_TENANTS_DETAIL: 'Can Use Tenant Emailer',
        SITE_PERMISSION_FORCE_COMPLETE_AUDITS_DETAIL: 'Force complete audits without answering all questions',

        SITE_PERMISSION_VIEW_EMAIL_LOGS: 'View Email Logs',
        SITE_PERMISSION_VIEW_EMAIL_LOGS_DETAIL: 'Can view other users emails ',

        SITE_PERMISSION_P2P: 'P2P',
        SITE_PERMISSION_P2P_DETAIL: '',
        SITE_PERMISSION_P2P_PROCESS_NOMINAL_CODINGS: 'Process P2P nominal codings',
        SITE_PERMISSION_P2P_PROCESS_NOMINAL_CODINGS_DETAIL: 'Can mark P2P nominal codings as processed',

        SITE_PERMISSION_ASSET_USER_IMPORTS_DETAIL: 'Import Assets',
        SITE_PERMISSION_TASK_USER_IMPORTS_DETAIL: 'Import Tasks',
        SITE_PERMISSION_ASSET_TASK_USER_IMPORTS_DETAIL: 'The ability to import tasks and assets',
        SITE_PERMISSION_ACTION_USER_IMPORTS_DETAIL: 'Import Actions',
        SITE_PERMISSION_METER_READINGS_USER_IMPORTS_DETAIL: 'The ability to import meter readings',

        SITE_PERMISSION_JOB_OPERATIVE: 'View Operatives',
        SITE_PERMISSION_JOB_OPERATIVE_DETAIL: 'Ability to select an operative on job',

        SITE_PERMISSION_BULK_REASSIGN_JOBS: 'Bulk Reassign Jobs',
        SITE_PERMISSION_BULK_REASSIGN_JOBS_DETAIL: 'Ability to bulk reassign jobs.',
        SITE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS: 'Bulk Reassign Job Operatives',
        SITE_PERMISSION_BULK_REASSIGN_OPERATIVE_JOBS_DETAIL: 'Ability to bulk reassign job operatives.',
        SITE_PERMISSION_BULK_CANCEL_JOBS: 'Bulk Cancel Jobs',
        SITE_PERMISSION_BULK_CANCEL_JOBS_DETAIL: 'Ability to bulk cancel jobs.',
        SITE_PERMISSION_BULK_CANCEL_ACTIONS: 'Bulk Cancel Actions',
        SITE_PERMISSION_BULK_CANCEL_ACTIONS_DETAIL: 'Ability to bulk cancel actions.',

        USER_STILL_AN_APPROVER: 'Cannot remove "Can Be Approver". This user is still an approver for at least one site.',
        AT_LEAST_ONE_USER_STILL_AN_APPROVER: 'At least one user is still an approver for a site.',
        CANNOT_REMOVE_APPROVER_PERMISSION: 'Cannot remove approver permission. User(s) with this role are currently approvers.',

        SITE_PERMISSION_VIEW_AUDIT_TEMPLATES: 'View Audit Templates',
        SITE_PERMISSION_CREATE_PUBLIC_AUDIT_TEMPLATES: 'Create Public Audit Templates',
        SITE_PERMISSION_VIEW_AUDIT_SCHEDULES: 'View Audit Schedules',
        SITE_PERMISSION_CREATE_AUDIT_SCHEDULES: 'Create Audit Schedules',

        SITE_PERMISSION_VIEW_AUDIT_TEMPLATES_DETAIL: 'View Audit Templates',
        SITE_PERMISSION_CREATE_PUBLIC_AUDIT_TEMPLATES_DETAIL: 'Create Public Audit Templates',
        SITE_PERMISSION_VIEW_AUDIT_SCHEDULES_DETAIL: 'View Audit Schedules',
        SITE_PERMISSION_CREATE_AUDIT_SCHEDULES_DETAIL: 'Create Audit Schedules',

        SITE_PERMISSION_EDIT_METER_REFERENCES: 'Edit Meter References',
        SITE_PERMISSION_EDIT_METER_REFERENCES_DETAIL: 'Edit Meter References',

        SITE_PERMISSION_WRITE_NFC_TAGS: 'Write NFC Tags',
        SITE_PERMISSION_WRITE_NFC_TAGS_DETAIL: 'Write NFC Tags',

        SITE_PERMISSION_ACTIVATE_SURVEY: 'Activate KPI Survey',
        SITE_PERMISSION_ACTIVATE_SURVEY_DETAIL: 'Activate KPI Survey',

        SITE_PERMISSION_NOTIFY_UPDATES: 'Notify Job/Quote Contacts',
        SITE_PERMISSION_NOTIFY_UPDATES_DETAIL: 'Notify Job/Quote Contacts of Updates',

        SITE_PERMISSION_VIEW_LINK_JOBS: 'View Links for jobs',
        SITE_PERMISSION_VIEW_LINK_JOBS_DETAIL: 'View Links for jobs',
        SITE_PERMISSION_REMOVE_LINK_JOBS: 'Delete Links for jobs',
        SITE_PERMISSION_REMOVE_LINK_JOBS_DETAIL: 'Delete Links for jobs',

        SITE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET: 'Edit Score Types On Asset',
        SITE_PERMISSION_EDIT_SCORE_TYPES_ON_ASSET_DETAIL: 'Edit Score Types when editing Assets',

        SITE_PERMISSION_OPEN_CLOSE: 'Create open/close shop records',
        SITE_PERMISSION_OPEN_CLOSE_DETAIL: 'The ability to create open/close shop records',
        SITE_PERMISSION_VIEW_OPEN_CLOSE: 'View open/close shop records',
        SITE_PERMISSION_VIEW_OPEN_CLOSE_DETAIL: 'The ability to view open/close shop records',

        SITE_PERMISSION_CREATE_CONTRACTS: 'Create Contracts',
        SITE_PERMISSION_CREATE_CONTRACTS_DETAIL: 'The ability to create contracts',

        SITE_PERMISSION_CREATE_SPILLAGES: 'Report Spillages',
        SITE_PERMISSION_CREATE_SPILLAGES_DETAIL: 'The ability to report spillages',
        SITE_PERMISSION_EDIT_SPILLAGES: 'Edit reported Spillages',
        SITE_PERMISSION_EDIT_SPILLAGES_DETAIL: 'The ability to edit reported spillages',
        SITE_PERMISSION_VIEW_SPILLAGES: 'View Spillages',
        SITE_PERMISSION_VIEW_SPILLAGES_DETAIL: 'The ability to view spillages',

        SITE_PERMISSION_VIEW_FILTER_BAR: 'View Filter Bar',
        SITE_PERMISSION_VIEW_FILTER_BAR_DETAIL: 'The ability to view Filter Bar',

        SITE_PERMISSION_BULK_NOTIFY_FILES: 'Bulk Notify Files',
        SITE_PERMISSION_BULK_NOTIFY_FILES_DETAIL: 'The ability to bulk notify expired files',

        SITE_PERMISSION_VIEW_JOB_FILES: 'View Job Files',
        SITE_PERMISSION_VIEW_JOB_FILES_DETAIL: 'The ability to view files that should be visible on Jobs',
        SITE_PERMISSION_EDIT_JOB_FILES: 'Edit Job Files',
        SITE_PERMISSION_EDIT_JOB_FILES_DETAIL: 'The ability to edit files that should be visible on Jobs',

        SITE_PERMISSION_VIEW_WASTE_ENTRIES: 'View Waste Entries',
        SITE_PERMISSION_VIEW_WASTE_ENTRIES_DETAIL: 'The ability to view waste entries',
        SITE_PERMISSION_CREATE_WASTE_ENTRIES: 'Create Waste Entries',
        SITE_PERMISSION_CREATE_WASTE_ENTRIES_DETAIL: 'The ability to create waste entries',
        SITE_PERMISSION_EDIT_WASTE_ENTRIES: 'Edit Waste Entries',
        SITE_PERMISSION_EDIT_WASTE_ENTRIES_DETAIL: 'The ability to edit waste entries',
        SITE_PERMISSION_IMPORT_WASTE_ENTRIES: 'Import Waste Entries',
        SITE_PERMISSION_IMPORT_WASTE_ENTRIES_DETAIL: 'The ability to import waste entries',

        SITE_PERMISSION_CREATE_AUDIT_ANSWER_OPTIONS: 'Create Audit Answer Options',
        SITE_PERMISSION_CREATE_AUDIT_ANSWER_OPTIONS_DETAIL: 'The ability to create audit answer options',
        SITE_PERMISSION_EDIT_AUDIT_ANSWER_OPTIONS: 'Edit Audit Answer Options',
        SITE_PERMISSION_EDIT_AUDIT_ANSWER_OPTIONS_DETAIL: 'The ability to edit audit answer options',
        SITE_PERMISSION_VIEW_AUDIT_ANSWER_OPTIONS: 'View Audit Answer Options',
        SITE_PERMISSION_VIEW_AUDIT_ANSWER_OPTIONS_DETAIL: 'The ability to view audit answer options',
        SITE_PERMISSION_CREATE_WEBHOOKS: 'Create Webhooks',
        SITE_PERMISSION_CREATE_WEBHOOKS_DETAIL: 'The ability to create webhooks',
        SITE_PERMISSION_EDIT_WEBHOOKS: 'Edit Webhooks',
        SITE_PERMISSION_EDIT_WEBHOOKS_DETAIL: 'The ability to edit webhooks',
        SITE_PERMISSION_VIEW_WEBHOOKS: 'View Webhooks',
        SITE_PERMISSION_VIEW_WEBHOOKS_DETAIL: 'The ability to webhooks',
        SITE_PERMISSION_APPROVE_ALL_QUOTES_PENDING_VALUE_APPROVAL: 'Approve All Quotes Pending Value Approval',
        SITE_PERMISSION_APPROVE_ALL_QUOTES_PENDING_VALUE_APPROVAL_DETAIL: 'The ability to approve all Quotes Pending Value Approval based on site access',
        SITE_PERMISSION_CAN_ADD_ADDITIONAL_OPERATIVES: 'Can Add Additional Operatives',
        SITE_PERMISSION_CAN_ADD_ADDITIONAL_OPERATIVES_DETAIL: 'The ability to add additional operatives',
        SITE_PERMISSION_CANCEL_ANY_JOB: 'Can Cancel All Jobs',
        SITE_PERMISSION_CANCEL_ANY_JOB_DETAIL: 'The ability to cancel any job',
        SITE_PERMISSION_REOPEN_JOB: 'Can Reopen All Jobs',
        SITE_PERMISSION_REOPEN_JOB_DETAIL: 'The ability to reopen jobs',
        SITE_PERMISSION_EDIT_PO_NUMBER: 'Edit PO Number',
        SITE_PERMISSION_EDIT_PO_NUMBER_DETAIL: 'The ability to edit job PO numbers',
        SITE_PERMISSION_VIEW_ALL_SERVICE_PROVIDERS: 'View All Service Providers',
        SITE_PERMISSION_VIEW_ALL_SERVICE_PROVIDERS_DETAIL: 'The ability to view all existing service providers',
        SITE_PERMISSION_RECEIVE_QUOTE_SUBMISSION_NOTIFICATIONS: 'Receive all Quote submission notifications',
        SITE_PERMISSION_RECEIVE_QUOTE_SUBMISSION_NOTIFICATIONS_DETAIL: 'User will receive notifications for all Quotes submitted on their Sites'
    });
});
