(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('SiteInfoController', SiteInfoController);

    SiteInfoController.$inject = [
        '$state',
        'apiClient',
        'confirmationModal',
        'siteResponse',
        'isActive',
        'messenger','lodash',
        'quoteThresholdsCollectionResponse',
        'thresholdService',
        'approversResponse',
        'modulesService',
        'isEditable',
        'meridianConfigurationCollectionResponse',
        'userManager',
        'siteAssociatesService',
        'rootResourceResponse'
    ];

    function SiteInfoController(
        $state,
        apiClient,
        confirmationModal,
        siteResponse,
        isActive,
        messenger,
        lodash,
        quoteThresholdsCollectionResponse,
        thresholdService,
        approversResponse,
        modulesService,
        isEditable,
        meridianConfigurationCollectionResponse,
        userManager,
        siteAssociatesService,
        rootResourceResponse
    )
        {
        var vm = this;
        vm.delete = deleteSite;
        vm.site = siteResponse;
        vm.isActive = isActive;
        vm.pcdEnabled = modulesService.isEnabled('pcd');
        vm.p2pEnabled = modulesService.isEnabled('p2p');
        vm.qComplianceTypeEnabled = modulesService.isEnabled('qcompliance');
        vm.riskwiseEnabled = modulesService.isEnabled('riskwise');
        vm.multiMeridian =  lodash.get(meridianConfigurationCollectionResponse, 'systemLinks', []).length;
        vm.ospEnabled = modulesService.isEnabled('osp');
        vm.associatesCollection = siteAssociatesService.getSiteAssociates(siteResponse, rootResourceResponse, {limit: 999});
        vm.quoteThresholds = quoteThresholdsCollectionResponse.quoteThresholds;
        vm.approvers = approversResponse;
        vm.address = siteResponse.contactInformation.addresses[0];
        vm.canEditSite = isEditable;
        vm.canAccessAssociates = siteResponse.getLink('memberships') && siteResponse.getLink('associates');
        vm.isClientAdmin = userManager.hasPermission('user_permission_client_admin_manage_sites') && !userManager.hasPermission('user_permission_view_sites');

        thresholdService.initThresholds(vm.quoteThresholds);
        vm.getQuoteApproverThreshold = thresholdService.getQuoteApproverThreshold;

        function deleteSite() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(vm.site.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('dashboard.admin.sites.list', {}, { reload: true }).then(function () {
                                messenger.success('SITE_DELETED');
                            });
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            );
        };
    }
})();
