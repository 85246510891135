(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sitegroups')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit);

    function registerRoutes ($stateProvider) {
        $stateProvider
           .state('dashboard.admin.sitegroups', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_site_groups']
                }
            });
    }

    function registerRoutesList ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sitegroups.list', {
                url: '/sitegroups?page&limit&order&name&id',
                parent: 'dashboard.admin.sitegroups',
                data: {
                    permissions: ['user_permission_view_site_groups']
                },
                views: {
                    '@dashboard.admin.sitegroups': {
                        templateUrl: '/modules/admin/site-groups/site-group-list.html',
                        controller: 'SiteGroupsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    name: null,
                    id: null,
                    active: { value: null }
                },
                resolve: {
                    siteGroupCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sitegroups'), $stateParams).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_GROUPS" | translate }}'
                }
            });
    }

    function registerRoutesAdd ($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sitegroups.list.add', {
                url: '/add',
                parent: 'dashboard.admin.sitegroups.list',
                data: {
                    permissions: ['user_permission_manage_site_groups']
                },
                resolve: {
                    siteGroupResponse: function () {
                        return {};
                    },
                    coreClientsCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('coreclients'), { 'active': 'yes', 'datagroup': 'search' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                views: {
                    '@dashboard.admin.sitegroups': {
                        templateUrl: '/modules/admin/site-groups/add-edit/site-group-form.html',
                        controller: 'SiteGroupsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
     }

    function registerRoutesDetails ($stateProvider) {
          $stateProvider
            .state('dashboard.admin.sitegroups.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.sitegroups.list',
                data: {
                    permissions: ['user_permission_view_site_groups']
                },
                views: {
                    '@dashboard.admin.sitegroups': {
                        templateUrl: '/modules/admin/site-groups/details/site-group-details.html',
                        controller: 'SiteGroupDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteGroupResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().siteGroupResponse.name }}'
                }
            })
            .state('dashboard.admin.sitegroups.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.sitegroups.list.details',
                data: {
                    permissions: ['user_permission_view_site_groups']
                },
                views: {
                    '@dashboard.admin.sitegroups.list.details': {
                        templateUrl: '/modules/admin/site-groups/details/info/site-group-info.html',
                        controller: 'SiteGroupInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.sitegroups.list.details.sites', {
                url: '/sites?sitePage&siteLimit&siteOrder&siteId&siteName',
                parent: 'dashboard.admin.sitegroups.list.details',
                data: {
                    permissions: ['user_permission_manage_site_groups'] // BE cgetAction requires manage site groups permission
                },
                views: {
                    '@dashboard.admin.sitegroups.list.details': {
                        templateUrl: '/modules/admin/site-groups/details/sites/site-group-sites.html',
                        controller: 'SiteGroupSitesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    selectedSiteCollectionResponse: function ($stateParams, apiClient, siteGroupResponse) {
                        return apiClient.get(siteGroupResponse.getLink('sites'), angular.extend({}, $stateParams, {
                            page: $stateParams.sitePage,
                            limit: $stateParams.siteLimit,
                            order: $stateParams.siteOrder,
                            id: $stateParams.siteId,
                            name: $stateParams.siteName
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                 params: {
                    sitePage: '1',
                    siteLimit: '30',
                    siteOrder: 'id',
                    siteId: null,
                    siteName: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITES" | translate }}'
                }
            })
            .state('dashboard.admin.sitegroups.list.details.sites.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sitegroups.list.details.sites',
                data: {
                    permissions: ['user_permission_manage_site_groups']
                },
                views: {
                    '@dashboard.admin.sitegroups.list.details': {
                        templateUrl: '/modules/admin/site-groups/details/sites/site-group-sites-form.html',
                        controller: 'SiteGroupSitesEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteCollectionResponse: function ($stateParams, rootResourceResponse, apiClient, user, userManager) {
                        var params = angular.extend({}, $stateParams, {'datagroup': 'search', 'page': 1});

                        if (userManager.hasPermission('user_permission_manage_sites')) {
                            return apiClient.getPage(rootResourceResponse.getLink('sites'), params);
                        }

                        return apiClient.getPage(user.getLink('sites'), params);
                    },
                    selectedSiteCollectionResponse: function(apiClient, siteGroupResponse) {
                        return apiClient.get(siteGroupResponse.getLink('sites'), { limit: 999999 }) || null;
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.sitegroups.list.details.document-sets', {
                url: '/document-sets?dlPage&dlLimit&dlOrder&dlId&dlName',
                parent: 'dashboard.admin.sitegroups.list.details',
                data: {
                    permissions: ['user_permission_view_site_groups', 'user_permission_view_document_types'] // BE cgetAction requires view doc types permission
                },
                views: {
                    '@dashboard.admin.sitegroups.list.details': {
                        templateUrl: '/modules/common/document-sets/list/document-sets.html',
                        controller: 'CommonDocumentSetsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    selectedDocumentSetCollectionResponse: function ($stateParams, apiClient, siteGroupResponse) {
                        if (siteGroupResponse.getLink('document-sets')) {
                            return apiClient.get(siteGroupResponse.getLink('document-sets'), angular.extend({}, $stateParams, {
                                page: $stateParams.dlPage,
                                limit: $stateParams.dlLimit,
                                order: $stateParams.dlOrder,
                                id: $stateParams.dlId,
                                name: $stateParams.dlName
                            })).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return null;
                        }
                    },
                    config: function() {
                        return {
                            entityName: 'SITE_GROUP_DOCUMENT_SETS',
                            addSref:'dashboard.admin.sitegroups.list.details.document-sets.add'
                        };
                    },
                },
                params: {
                    dlPage: '1',
                    dlLimit: '30',
                    dlOrder: 'id',
                    dlId: null,
                    dlName: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DOCUMENT_SETS" | translate }}'
                }
            })
            .state('dashboard.admin.sitegroups.list.details.document-sets.add', {
                url:'/add',
                parent: 'dashboard.admin.sitegroups.list.details.document-sets',
                data: {
                    permissions: ['user_permission_manage_site_groups', 'user_permission_manage_document_types'] // BE postAction requires manage doc types permission
                },
                views: {
                    '@dashboard.admin.sitegroups.list.details': {
                        templateUrl: '/modules/common/document-sets/add/document.set.form.html',
                        controller: 'CommonDocumentSetAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    documentSetCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documentsets'), {status: 'active'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        },
                    selectedDocumentSetCollectionResponse : function (apiClient, siteGroupResponse) {
                        return apiClient.get(siteGroupResponse.getLink('document-sets'))
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DOCUMENT_SET_ADD" | translate }}'
                }
            })
          ;
    }

    function registerRoutesEdit ($stateProvider) {
        $stateProvider
          .state('dashboard.admin.sitegroups.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sitegroups.list.details.info',
                data: {
                    permissions: ['user_permission_manage_site_groups']
                },
                views: {
                    '@dashboard.admin.sitegroups.list.details': {
                        templateUrl: '/modules/admin/site-groups/add-edit/site-group-form.html',
                        controller: 'SiteGroupsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    coreClientsCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('coreclients'), { 'active': 'yes', 'datagroup': 'search' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                 ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
     }
})();
