(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sitegroups')
        .controller('SiteGroupsController', SiteGroupsController);

    SiteGroupsController.$inject = ['$state', 'apiClient', '$stateParams', '$translate', 'siteGroupCollectionResponse'];

    function SiteGroupsController ($state, apiClient, $stateParams, $translate, siteGroupCollectionResponse) {
        var vm = this;
            vm.siteGroups = siteGroupCollectionResponse.sitegroups;
            vm.siteGroupCollectionResponse = siteGroupCollectionResponse;

           var activeOptions = [
                {
                    title: $translate.instant('NONE_SELECTED'),
                    value: null
                },
                {
                    title: $translate.instant('STATUS_ACTIVE'),
                    value: 'yes'
                },
                {
                    title: $translate.instant('STATUS_INACTIVE'),
                    value: 'no'
                }
            ];

            vm.criteria = {
                id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
                name: { type: 'text', value: $stateParams.name, title: 'NAME' },
                active: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.active, options: activeOptions },
            };

            vm.search = function (params) {
                var override = {
                    page: 1
                };

                $state.go('.', angular.extend({}, params, override), { reload: true });
            };

            vm.order = function (key) {
                $stateParams.order = key;

                $state.go('.', angular.extend({}, $stateParams), { reload: true });
            };
    }
})();
