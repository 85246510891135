(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit)
        .config(registerMarkupRoutesList)
        .config(registerMarkupRoutesDetails)
        .config(registerMarkupRoutesAdd)
        .config(registerMarkupRoutesEdit)
        .config(registerBillingSettings)
        .config(registerBillingSettingsEdit)
        .config(registerBillingSettingsAdd)
        .config(registerRoutesApprovers);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_core_clients']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients.list', {
                url: '/core-clients?page&limit&order&id&name&active',
                parent: 'dashboard.admin.core-clients',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients': {
                        templateUrl: '/modules/admin/core-clients/core-client-list.html',
                        controller: 'CoreClientsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null,
                    active: { value: null }
                },
                resolve: {
                    coreClientsCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('coreclients'), $stateParams).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CORE_CLIENT_LIST" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients.list.add', {
                url: '/add',
                parent: 'dashboard.admin.core-clients.list',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients': {
                        templateUrl: '/modules/admin/core-clients/add-edit/core-client-add-edit.html',
                        controller: 'CoreClientsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    coreClientResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients.list.details', {
                url: '/details/{coreClientresource}',
                abstract: true,
                parent: 'dashboard.admin.core-clients.list',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients': {
                        templateUrl: '/modules/admin/core-clients/details/core-client-details.html',
                        controller: 'CoreClientsDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    coreClientResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.coreClientresource.decode()).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().coreClientResponse.name }}'
                }
            })
            .state('dashboard.admin.core-clients.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.core-clients.list.details',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients.list.details': {
                        templateUrl: '/modules/admin/core-clients/details/info/core-client-info.html',
                        controller: 'CoreClientsInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.core-clients.list.details.info',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients.list.details': {
                        templateUrl: '/modules/admin/core-clients/add-edit/core-client-add-edit.html',
                        controller: 'CoreClientsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerMarkupRoutesList($stateProvider) {
        var markupParams = {
            markupPage: '1',
            markupLimit: '30',
            markupOrder: 'id'
        }

        $stateProvider
            .state('dashboard.admin.core-clients.list.details.markup', {
                url: '/markup?' + Object.keys(markupParams).join('&'),
                parent: 'dashboard.admin.core-clients.list.details',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients.list.details': {
                        templateUrl: '/modules/admin/core-clients/details/markup/markup-list.html',
                        controller: 'CoreClientsMarkupController',
                        controllerAs: 'vm'
                    }
                },
                params: markupParams,
                resolve: {
                    coreClientResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.coreClientresource.decode());
                    },
                    coreClientMarkupCollectionResponse: function(apiClient, coreClientResponse, $stateParams) {
                        var params = angular.copy($stateParams);
                        params = angular.extend({}, params, {
                            page:   $stateParams.markupPage,
                            order:  $stateParams.markupOrder,
                            limit:  $stateParams.markupLimit
                        });

                        return apiClient.get(coreClientResponse.getLink('markup'), params).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CORE_CLIENT_MARKUP" | translate }}'
                }
            });
    }

    function registerMarkupRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients.list.details.markup.details', {
                url: '/markup/{coreClientMarkupresource}/',
                parent: 'dashboard.admin.core-clients.list.details.markup',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                abstract: true,
                resolve: {
                    coreClientMarkupResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.coreClientMarkupresource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().coreClientMarkupResponse.id }}'
                }
            })
            .state('dashboard.admin.core-clients.list.details.markup.details.info', {
                url: '/info',
                parent: 'dashboard.admin.core-clients.list.details.markup.details',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients.list.details': {
                        templateUrl: '/modules/admin/core-clients/details/markup/details/info/markup-info.html',
                        controller: 'CoreClientsMarkupInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerMarkupRoutesAdd($stateProvider) {
        $stateProvider
        .state('dashboard.admin.core-clients.list.details.markup.add', {
            url: '/add',
            parent: 'dashboard.admin.core-clients.list.details.markup',
            data: {
                permissions: ['user_permission_manage_core_clients']
            },
            views: {
                '@dashboard.admin.core-clients.list.details': {
                    templateUrl: '/modules/admin/core-clients/details/markup/add-edit/markup-add-edit.html',
                    controller: 'CoreClientsMarkupAddEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                coreClientMarkupResponse: function() {
                    return null;
                },
                coreClientsCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                    return apiClient.get(rootResourceResponse.getLink('coreclients'), $stateParams).then( function(response) {
                        return response || apiClient.noResourceFound();
                    });
                }
            },
            ncyBreadcrumb: {
                label: '{{ ::"ADD" | translate }}'
            }
        });
    }

    function registerMarkupRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients.list.details.markup.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.core-clients.list.details.markup.details.info',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients.list.details': {
                        templateUrl: '/modules/admin/core-clients/details/markup/add-edit/markup-add-edit.html',
                        controller: 'CoreClientsMarkupAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerBillingSettings($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients.list.details.billing-settings', {
                url: '/billing-settings',
                parent: 'dashboard.admin.core-clients.list.details',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients.list.details': {
                        templateUrl: '/modules/common/billing-settings/details/billing-settings-details.html',
                        controller: 'CommonBillingSettingsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    billingSettingsResponse: function(apiClient, coreClientResponse, $stateParams) {
                        return apiClient.get(coreClientResponse.getLink('billingsettings'), {}).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    relatedCoreClientBillingSettingsResponse: function() {
                        return {};
                    },
                    approversResponse: function(billingSettingsResponse, lodash) {
                        if (billingSettingsResponse.billingsettings.length) {
                             var billingSettings = lodash.first(billingSettingsResponse.billingsettings);
                              return billingSettings.getResource('approvers');
                         }
                     },
                    config: function() {
                        return {
                            type: 'core-client'
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"BILLING_SETTINGS" | translate }}'
                }
            });
    }

    function registerBillingSettingsEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients.list.details.billing-settings.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.core-clients.list.details.billing-settings',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients.list.details': {
                        templateUrl: '/modules/common/billing-settings/details/add-edit/billing-settings-add-edit.html',
                        controller: 'CommonBillingSettingsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pricingSchedulesResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('pricing-schedules'), {}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
        })
    }

    function registerBillingSettingsAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients.list.details.billing-settings.add', {
                url: '/add',
                parent: 'dashboard.admin.core-clients.list.details.billing-settings',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients.list.details': {
                        templateUrl: '/modules/common/billing-settings/details/add-edit/billing-settings-add-edit.html',
                        controller: 'CommonBillingSettingsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pricingSchedulesResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('pricing-schedules'), {}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
        });
    }

    function registerRoutesApprovers($stateProvider) {
        $stateProvider
            .state('dashboard.admin.core-clients.list.details.billing-settings.approvers', {
                url: '/approvers',
                parent: 'dashboard.admin.core-clients.list.details.billing-settings',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.core-clients.list.details.billing-settings.approvers.edit', {
                url: '/edit',
                parent: 'dashboard.admin.core-clients.list.details.billing-settings.approvers',
                data: {
                    permissions: ['user_permission_manage_core_clients']
                },
                views: {
                    '@dashboard.admin.core-clients.list.details': {
                        templateUrl: '/modules/common/billing-settings/details/edit-approvers/approvers-form.html',
                        controller: 'CommonBillingSettingsApproversEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    userCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('users'), { status: 'active', limit: 30 });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"APPROVERS" | translate }}'
                }
            });
    }

})();
