(function() {
    'use strict';

    angular
        .module('elogbooks.admin.core-clients')
        .controller('CoreClientsController', CoreClientsController);

    CoreClientsController.$inject = ['$state', '$stateParams', 'coreClientsCollectionResponse', '$translate'];

    function CoreClientsController($state, $stateParams, coreClientsCollectionResponse, $translate) {
        var vm = this;
        vm.coreClients = coreClientsCollectionResponse.coreclients;
        vm.coreClientsCollectionResponse = coreClientsCollectionResponse;

        var activeOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('STATUS_ACTIVE'),
                value: 'yes'
            },
            {
                title: $translate.instant('STATUS_INACTIVE'),
                value: 'no'
            }
        ];


        vm.criteria = {
            id: { type: 'number', value: $stateParams.id ? parseInt($stateParams.id, 10) : null, title: 'ID', min: 1 },
            name: { type: 'text', title: 'NAME', value: $stateParams.name },
            active: { type: 'options', title: 'IS_ACTIVE', value: $stateParams.active, options: activeOptions },
        };

        vm.order = orderAction;
        vm.search = searchAction;

        function searchAction(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.order = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }
    }
})();
