(function(){
    'use strict';

    angular
        .module('elogbooks.common.sso')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {

        $stateProvider
            .state('sso', {
                url: '/link/sso',
                templateUrl: '/modules/common/sso/link-sso.html',
                controller: 'SsoController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"LINK_SSO" | translate }}'
                },
                resolve: {
                    rootResource: function (apiClient, $rootScope) {
                        return apiClient.get('/').then(function(response) {
                            $rootScope.theme = response.theme;
                            return response;
                        });
                    }
                }
            });
    }
})();
